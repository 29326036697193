<template>
  <v-card>
    <v-card-title class="text-h6">{{ $t("labels.roll_stamp") }}</v-card-title>
    <v-card-text
      >{{ $t("messages.scan_stamp_code") }}
      <span class="error--text">({{ needRoll }})</span></v-card-text
    >

    <v-card-text>
      <v-text-field
        v-model="code"
        class="c-input-xs"
        @keyup.enter="roll"
        :label="$t('labels.stamp_code')"
        :placeholder="$t('labels.stamp_code')"
        autofocus
        :disabled="needRoll === 0"
        dense
        single-line
        outlined
        clearable
        hide-details
      ></v-text-field>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.cancel") }}
      </v-btn>
      <v-btn
        color="green darken-1"
        text
        @click="confirm"
        :disabled="needRoll !== 0"
      >
        {{ $t("labels.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "ReceiptDirectRollUid",
  props: {
    goods: {
      type: Object,
      default: () => {},
    },
    insertTracking: {
      type: String,
      default: () => null,
    },
    receiptQuantity: {
      type: Number,
      default: () => 0,
    },
    printQuantity: {
      type: Number,
      default: () => 0,
    },
    uids: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    code: null,
    isLoading: false,
    rollUids: [],
  }),
  computed: {
    needRoll() {
      return this.printQuantity - this.rollUids.length - this.receiptQuantity;
    },
  },
  mounted() {},
  methods: {
    async roll() {
      if (!this.code) {
        return false;
      }

      if (!this.uids.includes(this.code)) {
        this.$vToastify.error("Mã UID không đúng");
        this.code = null;
        return false;
      }

      if (this.rollUids.includes(this.code)) {
        this.$vToastify.error("Mã UID đã quét");
        this.code = null;
        return false;
      }

      this.rollUids.push(this.code);
      this.code = null;

      /*if (this.needRoll === 0) {
        this.confirm()
      }*/
    },
    cancel() {
      this.$emit("cancel");
    },
    confirm() {
      this.$emit("confirm", this.rollUids);
    },
  },
};
</script>

<style scoped></style>
